import React from "react";
import styled from "styled-components";

const TextArea = ({ placeholder, changeEvent, name }) => {
  return (
    <TextAreaField
      placeholder={placeholder}
      name={name}
      onChange={changeEvent}
    />
  );
};

const TextAreaField = styled.textarea`
  font-size: 1rem;
  line-height: 1.25;
  font-family: 'Archivo';
  font-weight: 600;
  padding: 0.85rem 0.75rem;
  background: #fff;
  border-radius: 6px;
  margin-top: 15px;
  margin-bottom: 15px;
  box-shadow: inset 0 1px 2px 0 rgb(36 36 36 / 10%),
    inset 0 -1px 0 0 hsl(0deg 0% 100% / 50%), inset 0 0 0 1px rgb(0 0 0 / 10%);
  border: none;
  height: 90px;
`;
export default TextArea;
