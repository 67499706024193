import React from "react";
import styled from "styled-components";

const SelectDropdown = ({ data, changeEvent, name }) => {
  return (
    <Select name={name} onChange={changeEvent}>
      {data.map((item) => (
        <Option key={item.value} value={item.value}>
          {item.value}
        </Option>
      ))}
    </Select>
  );
};

const Select = styled.select`
  font-size: 1rem;
  line-height: 1.25;
  font-family: 'Archivo';
  font-weight: 600;
  color: grey;
  padding: 0.85rem 0.75rem;
  background: #fff;
  border-radius: 6px;
  margin-top: 15px;
  margin-bottom: 15px;
  box-shadow: inset 0 1px 2px 0 rgb(36 36 36 / 10%),
    inset 0 -1px 0 0 hsl(0deg 0% 100% / 50%), inset 0 0 0 1px rgb(0 0 0 / 10%);
  border: none;
  height: auto;
  @media (max-width: 970px) {
    font-size: 12px;
    margin-bottom: 5px;
    margin-top: 5px;
    height: 40px;
  }
`;
const Option = styled.option`
  background: #fff;
  height: 60px;
  &:checked {
    background: lightskyblue;
  }
`;

export default SelectDropdown;
