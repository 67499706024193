import React from "react";
import styled from "styled-components";

const InputField = ({ changeEvent, name, type, placeholder }) => {
  return (
    <Input
      name={name}
      onChange={changeEvent}
      type={type || "text"}
      placeholder={placeholder}
    />
  );
};

const Input = styled.input`
  font-size: 1rem;
  line-height: 1.25;
  font-family: 'Archivo';
  font-weight: 600;
  padding: 0.85rem 0.75rem;
  background: #fff;
  border-radius: 6px;
  margin-top: 15px;
  margin-bottom: 15px;
  box-shadow: inset 0 1px 2px 0 rgb(36 36 36 / 10%),
    inset 0 -1px 0 0 hsl(0deg 0% 100% / 50%), inset 0 0 0 1px rgb(0 0 0 / 10%);
  border: none;
  height: auto;
  @media (max-width: 970px) {
    font-size: 12px;
    margin-bottom: 5px;
    margin-top: 5px;
    height: 40px;
  }
`;

export default InputField;
