import React from "react";
import ConsultForm from "./ConsultUsModalForm";
import { FaTimes } from "react-icons/fa";
import styled from "styled-components";
import ModalButton from "./Button";
import Modal from "@material-ui/core/Modal";

function ConsultModal({ buttonText }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container>
      <StyledButtonContainer onClick={handleOpen}>
        <ModalButton value={buttonText || "Get Free Consulting"} />
      </StyledButtonContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{ overflow: "scroll", marginTop: "40px" }}
      >
        <ContentContainer>
          <StyledTimes size={15} onClick={() => setOpen(false)} />
          <StyledModalContent>
            <StyledHeader>APPLICATION DEVELOPMENT SERVICES</StyledHeader>
            <StyledDescription>
              Please select your type of project request and submit the form, or
              contact us at hello@scrumanac.com for more information on
              Srumanac's services and technologies.
            </StyledDescription>
            <ConsultForm />
          </StyledModalContent>
        </ContentContainer>
      </Modal>
    </Container>
  );
}

const Container = styled.div`
  @media (max-width: 970px) {
    margin-bottom: 20px;
  }
`;

const ContentContainer = styled.div`
  padding: 16px 32px 24px;
  position: absolute;
  width: 500px;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 10px;
  margin-top: 10px;
  box-shadow: grey;
  top: 45%;
  left: 50%;
  overflow-x: hidden;
  transform: translate(-45%, -45%);

  @media (max-width: 970px) {
    width: 85%;
    left: 45%;
    padding-top: 30px;

    padding-left: 4px;
  }
`;

const StyledDescription = styled.p`
  font-size: 1rem;
  margin-bottom: 1rem;
  color: black;
  @media (max-width: 970px) {
    font-size: 14px;
    margin-bottom: 5px;
  }
`;

const StyledHeader = styled.p`
  color: black;
  font-size: 1.25rem;
  line-height: 1.25;
  font-family: 'Archivo';
  font-weight: 600;
  margin-bottom: 0.75rem;
  @media (max-width: 970px) {
    font-size: 12px;
    margin-bottom: 5px;
  }
`;

const StyledModalContent = styled.div`
  position: relative;
  display: -ms-flexbox;
  padding-right: 2rem;
  border: 0px !important;
  padding-left: 2rem;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  @media (max-width: 970px) {
    font-size: 13px;
    padding-top: 1.5rem;
    padding-right: 0px;
  }
`;

const StyledTimes = styled(FaTimes)`
  margin-left: 100%;
  opacity: 0.8;
  font-size: 1.5rem;
  font-weight: 100;
  color: #242424;
  &:hover {
    opacity: 1;
    color: #191919;
  }
  @media (max-width: 970px) {
    margin-top: 20px;
  }
`;

const StyledButtonContainer = styled.p`
  margin-top: 10rem;
  display: flex;
  padding-left: 20px;
  max-width: 400px;
  @media (max-width: 970px) {
    justify-content: center;
    padding-left: 0px;
    font-size: 10px;
  }
`;

export default ConsultModal;
