import React from "react";
import styled from "styled-components";
import { FaChevronRight } from "react-icons/fa";
// import Axios from "axios";
// import Loader from "../../img/loader.gif";

const Button = ({ value, width, formValue }) => {
  // const Url =
  //   "https://m2ep0yt5j1.execute-api.ap-northeast-1.amazonaws.com/dev/contact";

  return (
    <ButtonComp width={width}>
      <p> {value}</p>
      <p>
        {" "}
        <StyledChevronRight size={20} />
      </p>
    </ButtonComp>
  );
};

const ButtonComp = styled.button`
  box-shadow: none;
  border: 3px solid #0066b2;
  opacity: 0.75;
  height: 4rem;
  display: flex;
  width: ${(props) => (props.width ? props.width : "80%")};
  flex-wrap: nowrap;
  margin-top: 15px;
  justify-content: center;
  border-radius: 6px;
  align-items: center;
  font-size: 15px;
  color: #fff;
  font-family: 'Archivo';
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  will-change: background, opacity;
  background: #0066b2;
  transition: background 0.3s, opacity 0.3s;
  opacity: 0.9;
  &:hover {
    opacity: 1;
    color: #242424;
  }
  @media (max-width: 970px) {
    display: flex;
    height: 5rem;
    width: 70%;
    align-items: center;
    margin-right: 25px;
    font-size: 12px;
  }

  @media (max-width: 542px) {
    margin-right: 25px;
  }

  @media (max-width: 415px) {
    margin-right: 25px;
  }
  @media (max-width: 370px) {
    margin-right: 25px;
  }
`;
const StyledChevronRight = styled(FaChevronRight)`
  margin-left: 8px;
  margin-top: 4px;
  @media (max-width: 970px) {
    margin-top: -2px;
  }
`;

// const StyledLoader = styled.img`
//   width: 30px;
//   height: 30px;
// `;

export default Button;
