import React from "react";
import styled from "styled-components";
import { FaChevronRight } from "react-icons/fa";
import Axios from "axios";
import Loader from "../../img/loader.gif";

const SubmitButton = ({
  value,
  width,
  formValue,
  submitStatus,
  setSubmitStatus,
  setAlertStatus,
  formRef,
}) => {
  const Url =
    "https://m2ep0yt5j1.execute-api.ap-northeast-1.amazonaws.com/dev/contact";

  const ButtonClick = (e) => {
    e.preventDefault();
    setSubmitStatus(true);

    Axios.post(Url, formValue)
      .then((data) => {
        setSubmitStatus(false);
        setAlertStatus(true);
        formRef.current.reset();
      })
      .catch((error) => {
        setSubmitStatus(false);
        formRef.current.reset();
        setAlertStatus(true);
      });
  };
  return (
    <ButtonComp width={width} onClick={ButtonClick}>
      {!submitStatus ? (
        <>
          <p> {value}</p>
          <p>
            {" "}
            <StyledChevronRight size={20} />{" "}
          </p>{" "}
        </>
      ) : (
        <StyledLoader src={Loader} />
      )}
    </ButtonComp>
  );
};

const ButtonComp = styled.button`
  box-shadow: none;
  border: 3px solid #0066b2;
  opacity: 0.75;
  height: 4rem;
  display: flex;
  width: ${(props) => (props.width ? props.width : "100%")};
  flex-wrap: nowrap;
  padding-top: 17px;
  padding-left: 10px;
  margin-top: 15px;
  padding-right: 10px;
  justify-content: center;
  border-radius: 6px;
  font-size: 15px;
  color: #fff;
  font-family: 'Archivo';
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  will-change: background, opacity;
  background: #0066b2;
  -webkit-transition: background 0.3s, opacity 0.3s;
  transition: background 0.3s, opacity 0.3s;
  opacity: 0.9;
  &:hover {
    opacity: 1;
    color: #242424;
  }

  @media (max-width: 970px) {
    padding-top: 25px;
    height: 5rem;
    width: 100%;
    margin-right: 25px;
    font-size: 12px;
  }

  @media (max-width: 542px) {
    margin-right: 25px;
  }

  @media (max-width: 415px) {
    margin-right: 25px;
  }
  @media (max-width: 370px) {
    margin-right: 25px;
  }
`;
const StyledChevronRight = styled(FaChevronRight)`
  margin-left: 10px;
  @media (max-width: 970px) {
    margin-top: -3px;
  }
`;

const StyledLoader = styled.img`
  width: 30px;
  height: 30px;
`;

export default SubmitButton;
