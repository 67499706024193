import React, { useState, useRef } from "react";
import styled from "styled-components";
import Select from "./SelectDropdown";
import InputField from "./InputField";
import Button from './SubmitButton';
import TextAreaField from "./TextArea";
import { GrFormClose } from "react-icons/gr";

const Selectdata = [
  { value: "Free Consulting" },
  { value: "WEB or Mobile App" },

  { value: "Project Estimate" },
  { value: "Team Extension" },
  { value: "Dedicated Team" },
  { value: "BOT Model" },
  { value: "Others" },
];

const ConsultUsModalForm = () => {
  const  [submitContact,setSubmitContact] = useState(false);
  const  [showAlert,setShowAlert] = useState(false);
  const [inputValue, setInputValue] = useState({
    name: "",
    email: "",
    message: "",
    service: "",
    type: "Consultation",
    phone: "",
  });
  const AlertRef = useRef(null);
  const FormRef = useRef(null);



  const handleChange = (e) => {
    e.preventDefault();
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  };


  const CloseAlert = (e) => {
    AlertRef.current.style.opacity = "0";
  };

  return (
    <SectionContainer>
      <AlerBox ShowAlert={showAlert} ref={AlertRef}>
        <h4>Message recieved ,you will get a response shortly</h4>
        <StyledCloseIcon size={27} onClick={CloseAlert} />
      </AlerBox>
      <Container>
        <StyledForm ref={FormRef}>
          <Select name="service" data={Selectdata} changeEvent={handleChange} />
          <InputField
            changeEvent={handleChange}
            placeholder={"Name"}
            name="name"
          />
          <InputField
            placeholder={"Email Address"}
            name="email"
            changeEvent={handleChange}
          />
          <TextAreaField
            placeholder="Please provide more details"
            name="message"
            changeEvent={handleChange}
          />
          <Button formRef={FormRef} type="submit" value={"Submit"} formValue={inputValue} submitStatus={submitContact} setSubmitStatus={setSubmitContact} setAlertStatus={setShowAlert} />
        </StyledForm>
      </Container>
    </SectionContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -140px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;



const AlerBox = styled.div`
  margin-top: 0px;
  margin-bottom: 150px;
  background-color: #ed2939; /* Red */
  color: white;
  font-size: 15px;
  height: 40px;
  display: flex;
  border-radius: 6px;
  justify-content: space-between;
  padding-left: 3px;
  padding-top: 8px;
  opacity: ${props=>props.ShowAlert?1:0};
  /* opacity: 1; */
  transition: opacity 1s;
`;

const StyledCloseIcon = styled(GrFormClose)`
  color: #0066b2;
  border-radius: 6px;
  cursor: pointer;
`;

export default ConsultUsModalForm;
